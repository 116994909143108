import React from "react";
import {
  List,
  Edit,
  Create,
  Datagrid,
  TextField,
  EditButton,
  SimpleForm,
  TextInput,
  DateInput,
  ReferenceArrayInput,
  SelectArrayInput,
  required,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  DateField
} from "react-admin";

export const ProgramList = (props: any) => (
  <List {...props} title="Programlar">
    <Datagrid>
      <TextField source="id" />
      <TextField source="program_ismi" />
      <ReferenceArrayField
        label="Salonlar"
        reference="Salonlar"
        source="salon_ids"
      >
        <SingleFieldList>
          <ChipField source="salon_ismi" />
        </SingleFieldList>
      </ReferenceArrayField>
      <DateField source="tarih" label="Tarih" />
      <EditButton />
    </Datagrid>
  </List>
);

export const ProgramEdit = (props: any) => (
  <Edit {...props}>
    <SimpleForm redirect="list">
      <TextInput
        validate={required()}
        label="Program İsmi"
        source="program_ismi"
      />
      <DateInput validate={required()} label="Tarih" source="tarih" />
      <ReferenceArrayInput
        validate={required()}
        label="Salonlar"
        source="salon_ids"
        reference="Salonlar"
      >
        <SelectArrayInput optionText="salon_ismi" />
      </ReferenceArrayInput>
    </SimpleForm>
  </Edit>
);

export const ProgramCreate = (props: any) => (
  <Create {...props} title="Program Oluştur">
    <SimpleForm redirect="list">
      <TextInput
        validate={required()}
        label="Program İsmi"
        source="program_ismi"
      />
      <DateInput validate={required()} label="Tarih" source="tarih" />
      <ReferenceArrayInput
        validate={required()}
        label="Salonlar"
        source="salon_ids"
        reference="Salonlar"
      >
        <SelectArrayInput optionText="salon_ismi" />
      </ReferenceArrayInput>
    </SimpleForm>
  </Create>
);
