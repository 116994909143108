import React from "react";
import {
  List,
  Edit,
  Create,
  Datagrid,
  TextField,
  EditButton,
  SimpleForm,
  TextInput,
  EmailField,
  required
} from "react-admin";

export const KullaniciList = (props: any) => (
  <List {...props} title="Kullanıcılar">
    <Datagrid>
      <TextField source="id" />
      <TextField source="isim" />
      <TextField source="soyad" />
      <TextField source="telefon_no" />
      <EmailField source="email" />
      <EditButton />
    </Datagrid>
  </List>
);

export const KullaniciEdit = (props: any) => (
  <Edit {...props}>
    <SimpleForm redirect="list">
      <TextInput validate={required()} label="Adı" source="isim" />
      <TextInput validate={required()} label="Soyad" source="soyad" />
      <TextInput
        validate={required()}
        label="Email"
        source="email"
        type="email"
      />
      <TextInput
        validate={required()}
        label="Telefon Numarası"
        source="telefon_no"
      />
    </SimpleForm>
  </Edit>
);

export const KullaniciCreate = (props: any) => (
  <Create {...props}>
    <SimpleForm redirect="list">
      <TextInput validate={required()} label="Adı" source="isim" />
      <TextInput validate={required()} label="Soyad" source="soyad" />
      <TextInput validate={required()} label="Email" source="email" />
      <TextInput
        validate={required()}
        label="Telefon Numarası"
        source="telefon_no"
      />
    </SimpleForm>
  </Create>
);
