import React from "react";
import {
  List,
  Edit,
  Create,
  Datagrid,
  TextField,
  EditButton,
  DisabledInput,
  SimpleForm,
  TextInput,
  LongTextInput,
  required
} from "react-admin";

export const SalonList = (props: any) => (
  <List {...props} title="Salonlar">
    <Datagrid>
        <TextField source="id" />
      <TextField source="salon_ismi" />
      <EditButton />
    </Datagrid>
  </List>
);

export const SalonEdit = (props: any) => (
  <Edit {...props}>
    <SimpleForm redirect="list">
      <DisabledInput source="id" />
      <TextInput validate={required()} source="salon_ismi" />
      <LongTextInput validate={required()} label="Detay" source="detay" />
    </SimpleForm>
  </Edit>
);

export const SalonCreate = (props: any) => (
  <Create {...props}>
    <SimpleForm redirect="list">
      <TextInput validate={required()} label="Salonun İsmi" source="salon_ismi" />
      <LongTextInput validate={required()} label="Detay" source="detay" />
    </SimpleForm>
  </Create>
);
