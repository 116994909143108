import React from "react";
import {
  List,
  Edit,
  Create,
  Datagrid,
  TextField,
  EditButton,
  DisabledInput,
  SimpleForm,
  TextInput,
  LongTextInput,
  required
} from "react-admin";

export const KonusmaciList = (props: any) => (
  <List {...props} title="Salonlar">
    <Datagrid>
      <TextField source="id" />
      <TextField label="Adı Soyadı" source="isim_soyisim" />
      <EditButton />
    </Datagrid>
  </List>
);

export const KonusmaciEdit = (props: any) => (
  <Edit {...props}>
    <SimpleForm redirect="list">
      <DisabledInput source="id" />
      <TextInput
        validate={required()}
        label="Adı Soyadı"
        source="isim_soyisim"
      />
      <TextInput validate={required()} label="Görevi" source="gorev" />
      <TextInput validate={required()} label="Şirket" source="sirket" />
      <LongTextInput validate={required()} label="Özgeçmiş" source="ozgecmis" />
      <LongTextInput validate={required()} label="Hakkında" source="hakkinda" />
      <TextInput label="İnstagram" source="instagram" />
      <TextInput label="Twitter" source="twitter" />
      <TextInput label="Facebook" source="facebook" />
    </SimpleForm>
  </Edit>
);

export const KonusmaciCreate = (props: any) => (
  <Create {...props}>
    <SimpleForm redirect="list">
      <TextInput
        validate={required()}
        label="Adı Soyadı"
        source="isim_soyisim"
      />
      <TextInput validate={required()} label="Görevi" source="gorev" />
      <TextInput validate={required()} label="Şirket" source="sirket" />
      <LongTextInput validate={required()} label="Özgeçmiş" source="ozgecmis" />
      <LongTextInput validate={required()} label="Hakkında" source="hakkinda" />
      <TextInput label="İnstagram" source="instagram" />
      <TextInput label="Twitter" source="twitter" />
      <TextInput label="Facebook" source="facebook" />
    </SimpleForm>
  </Create>
);
