import React from "react";
import { Admin, Resource, Login } from "react-admin";
import {
  FirebaseRealTimeSaga,
  FirebaseDataProvider,
  FirebaseAuthProvider,
  RAFirebaseOptions
} from "react-admin-firebase";
import { SalonList, SalonCreate, SalonEdit } from "./screens/Salon";
import { ProgramList, ProgramCreate, ProgramEdit } from "./screens/Program";
import { OtelCreate, OtelEdit, OtelList } from "./screens/Otel";
import { EtkinlikEdit, EtkinlikList, EtkinlikCreate } from "./screens/Etkinlik";
import {
  KullaniciList,
  KullaniciCreate,
  KullaniciEdit
} from "./screens/Kullanici";

import {
  KonusmaciList,
  KonusmaciEdit,
  KonusmaciCreate
} from "./screens/Konusmaci";

import turkishMessages from "ra-language-turkish";
const messages = {
  tr: turkishMessages
};

// @ts-ignore
const i18nProvider = locale => messages[locale];

const firebaseConfig = {
  apiKey: "AIzaSyBZ_u-W3UrSMMK9QjUu-BXqxCU0PWC8BEo",
  authDomain: "mindup-3f806.firebaseapp.com",
  databaseURL: "https://mindup-3f806.firebaseio.com",
  projectId: "mindup-3f806",
  storageBucket: "mindup-3f806.appspot.com",
  messagingSenderId: "350183820733",
  appId: "1:350183820733:web:84c5c84ed1eb63d3be16d4",
  measurementId: "G-E2RZSB59BG"
};

const options: RAFirebaseOptions = {
  logging: true,
  watch: ["salon"]
};

const dataProvider = FirebaseDataProvider(firebaseConfig, options);
const authProvider = FirebaseAuthProvider(firebaseConfig, options);
const firebaseRealtime = FirebaseRealTimeSaga(dataProvider, options);

const MyLoginPage = () => (
  <Login backgroundImage="https://images.unsplash.com/photo-1550348390-b9346a0edaa2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2550&q=80" />
);

class App extends React.Component {
  render() {
    return (
      <Admin
        customSagas={[firebaseRealtime]}
        dataProvider={dataProvider}
        authProvider={authProvider}
        locale="tr"
        i18nProvider={i18nProvider}
        loginPage={MyLoginPage}
      >
        <Resource
          name="Salonlar"
          list={SalonList}
          create={SalonCreate}
          edit={SalonEdit}
          options={{ label: "Salonlar" }}
        />
        <Resource
          name="Etkinlikler"
          list={EtkinlikList}
          create={EtkinlikCreate}
          edit={EtkinlikEdit}
          options={{
            label: "Etkinlikler"
          }}
        />
        <Resource
          name="Programlar"
          list={ProgramList}
          create={ProgramCreate}
          edit={ProgramEdit}
          options={{ label: "Programlar" }}
        />
        <Resource
          name="Konuşmacılar"
          list={KonusmaciList}
          create={KonusmaciCreate}
          edit={KonusmaciEdit}
          options={{ label: "Konuşmacıları" }}
        />
        <Resource
          name="Kullanıcılar"
          list={KullaniciList}
          create={KullaniciCreate}
          edit={KullaniciEdit}
          options={{ label: "Kullanıcılar" }}
        />
        <Resource
          name="Oteller"
          list={OtelList}
          create={OtelCreate}
          edit={OtelEdit}
          options={{ label: "Oteller" }}
        />
      </Admin>
    );
  }
}

export default App;
