import React from "react";
import {
  List,
  Edit,
  Create,
  Datagrid,
  TextField,
  EditButton,
  DisabledInput,
  SimpleForm,
  TextInput,
  LongTextInput,
  required,
  DateField,
  DateInput
} from "react-admin";

export const EtkinlikList = (props: any) => (
  <List {...props} title="Etkinlikler">
    <Datagrid>
      <TextField source="id" />
      <TextField source="etkinlik_ismi" />
      <DateField source="baslangic_tarihi" />
      <DateField source="bitis_tarihi" />
      <EditButton />
    </Datagrid>
  </List>
);

export const EtkinlikEdit = (props: any) => (
  <Edit {...props}>
    <SimpleForm redirect="list">
      <DisabledInput source="id" />

      <TextInput
        validate={required()}
        label="Etkinlik İsmi"
        source="etkinlik_ismi"
      />
      <TextInput
        validate={required()}
        label="Etkinlik Resmi"
        source="etkinlik_resmi"
      />
      <DateInput
        validate={required()}
        label="Baslangic Tarihi"
        source="baslangic_tarihi"
      />
      <DateInput
        validate={required()}
        label="Bitis Tarihi"
        source="bitis_tarihi"
      />
      <TextInput source="lokasyon" />
    </SimpleForm>
  </Edit>
);

export const EtkinlikCreate = (props: any) => (
  <Create {...props}>
    <SimpleForm redirect="list">
      <TextInput
        validate={required()}
        label="Etkinlik İsmi"
        source="etkinlik_ismi"
      />
      <TextInput
        validate={required()}
        label="Etkinlik Resmi"
        source="etkinlik_resmi"
      />
      <DateInput
        validate={required()}
        label="Baslangic Tarihi"
        source="baslangic_tarihi"
      />
      <DateInput
        validate={required()}
        label="Bitis Tarihi"
        source="bitis_tarihi"
      />
      <TextInput source="lokasyon" />
    </SimpleForm>
  </Create>
);
